import GATSBY_COMPILED_MDX from "/vercel/path0/content/blog-posts/new-layout/index.md";
import {MDXProvider} from '@mdx-js/react';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
const BlogPost = ({children, data: {mdx: {frontmatter: {title, date}}}}) => React.createElement(Layout, {
  withHeader: true
}, React.createElement("div", {
  className: "container"
}, React.createElement("article", {
  className: "prose prose-lg mx-auto pt-16 pb-16 md:pb-24 lg:pb-32"
}, React.createElement("h1", null, title), date && React.createElement("date", {
  className: "text-base text-gray-500"
}, date), React.createElement(MDXProvider, null, children))));
BlogPost.propTypes = {
  children: PropTypes.node.isRequired
};
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query BlogPostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      excerpt
    }
  }
`;
export const Head = ({location: {pathname}, data: {mdx: {frontmatter: {title}, excerpt}}}) => React.createElement(SEO, {
  title: title,
  description: excerpt,
  pathname: pathname
});
